import React from "react"
import {graphql} from "gatsby"
import EventListingWithFilter from "Layout/Events/EventListingWithFilter/EventListingWithFilter"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"

const Events = ({
  location,
  pageContext,
  data
}) => {

  const {events, page, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      <EventListingWithFilter events={events} />
    </MainLayout>
  )
}

export const query = graphql`
  query eventsPageQuery {
    page: wordpressPage(wordpress_id: {eq: 61}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    events: allWordpressWpEventPosts {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          locations
          years
          types
          categories_names {
            name
            taxonomy
          }
          acf {
            event {
              event_facts
              speaker_facts
              event_subtitle
              speaker_name
              event_venue
              event_date_info {
                event_date_info_end_date
                event_date_info_end_time
                event_date_info_start_date
                event_date_info_start_time
              }
              event_images {
                event_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1646) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Events
